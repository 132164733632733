import { useContext, useState, useCallback, useEffect } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { convertMoneyTextToPennies, formatPennies } from "shared/Util/money";
import {
  CC_DEPOSIT_FEE,
  ACH_DEPOSIT_FEE,
  minimumDepositInPennies,
} from "shared/Config";
import PaylianceDepositSummaryView from "./PaylianceDepositSummaryView";
import useStyles from "Hooks/useStyles";
import PaylianceDepositSummaryStyles from "./PaylianceDepositSummaryStyles";
// import useIsInAnApprovedStateByIp from "Hooks/useIsInAnApprovedStateByIp";
import useCurrentLocationByIp from "Hooks/useCurrentLocationByIp";
import useCurrentLocationByGeo from "Hooks/useCurrentLocationByGeo";
import { Platform } from "react-native";
import { gql, useMutation, useQuery } from "@apollo/client";

const userPaymentsQuery = gql`
  query PaylianceListMyPaymentMethods {
    paylianceListMyPaymentMethods {
      cardId
      cardNumber
      expirationDate
    }
  }
`;

// const payliancePaymentMethodQuery = gql`
//   query PaylianceGetPaymentMethod($cardId: String!) {
//     paylianceGetPaymentMethod(cardId: $cardId) {
//       cardNumber
//     }
//   }
// `;

const paylianceChargePaymentMethodMutation = gql`
  mutation PaylianceChargePaymentMethod(
    $input: PaylianceChargePaymentMethodInput!
  ) {
    paylianceChargePaymentMethod(input: $input) {
      transactionId
      network
      networkResponse
      transactionStatus
      approvalCode
      avsResponse
      securityResponse
      successful
      message
    }
  }
`;

export default ({
  navigation,
  amount: amountFromParams,
  lastFour: lastFourFromParams,
  promotionId,
  type,
}) => {
  const { theme, styles } = useStyles({
    Styles: PaylianceDepositSummaryStyles,
  });
  const [cvv, setCvv] = useState("");
  const currentUser = useContext(CurrentUserContext);
  const [amount, setAmount] = useState(amountFromParams);
  const [lastFour, setLastFour] = useState(lastFourFromParams);
  const [submitted, setSubmitted] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const locationByIp = useCurrentLocationByIp();
  const locationByGeo = useCurrentLocationByGeo();
  const location = Platform.OS === "web" ? locationByIp : locationByGeo;
  // eslint-disable-next-line no-unused-vars
  const isGeographicallyAllowedToDeposit =
    !!currentUser?.groups?.includes?.("Admins") || location?.isAllowedToDeposit;

  const { data: userPaymentData } = useQuery(userPaymentsQuery);

  const locationString = !!location && `${location.city}, ${location.region}`;

  const navigateTo = useCallback(
    (screen, params, type) => () =>
      [
        setSubmitted(false),
        navigation[type === "replace" ? "replace" : "navigate"](screen, params),
      ],
    [navigation]
  );

  const selectedCreditCard = !!lastFour
    ? (userPaymentData?.paylianceListMyPaymentMethods ?? []).find(
        pm => pm.cardNumber === lastFour
      )
    : userPaymentData?.paylianceListMyPaymentMethods?.[0];

  const selectedPaymentMethod = selectedCreditCard;

  console.log(
    "selectedPaymentMethod",
    selectedPaymentMethod,
    userPaymentData?.paylianceListMyPaymentMethods?.[0]
  );

  const handleCvvChange = useCallback(cvv => setCvv(cvv), [setCvv]);

  const [checkout, { error, loading }] = useMutation(
    paylianceChargePaymentMethodMutation,
    {
      variables: {
        input: {
          cardId: selectedCreditCard?.cardId ?? "<>",
          amount: Number(amount),
          securityCode: cvv,
          promotionId: promotion?.id,
        },
      },
    }
  );

  const doSubmit = useCallback(
    () => checkout().then(() => setSubmitted(true)),
    [checkout]
  );

  const fee = type === "cc" ? CC_DEPOSIT_FEE : ACH_DEPOSIT_FEE;
  const bonus = formatPennies(
    promotion
      ? Math.min(
          parseInt(convertMoneyTextToPennies(amount)),
          promotion.type === "DEPOSIT_MATCH_100" ? 10000 : 50000
        )
      : 0
  );

  const handleVerificationPress = useCallback(
    () => navigation.push("Verification"),
    [navigation]
  );

  const errorMessages = [
    convertMoneyTextToPennies(amount) >= minimumDepositInPennies
      ? null
      : "$5 minimum",
    selectedPaymentMethod ? null : "You must select or add a payment method",
  ].filter(i => !!i);

  const isValid =
    !errorMessages.length &&
    !!currentUser?.verifiedAt &&
    (type === "cc" ? !!cvv : true);

  // useEffect(() => {
  //   Promise.all(
  //     (userPaymentData?.listUserPaymentMethodsByUserId?.items ?? []).map(item =>
  //       paylianceGetPaymentMethod({ variables: { cardId: item.cardId } })
  //     )
  //   ).then(paylianceGetPaymentMethodResponses =>
  //     setPayliancePaymentMethods(
  //       paylianceGetPaymentMethodResponses.map(p => p.cardNumber)
  //     )
  //   );
  // }, [
  //   paylianceGetPaymentMethod,
  //   userPaymentData?.listUserPaymentMethodsByUserId?.items,
  // ]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setSubmitted(false);
    });

    return unsubscribe;
  }, [navigation, setSubmitted]);

  useEffect(() => {
    if (lastFourFromParams && lastFourFromParams !== lastFour) {
      setLastFour(lastFourFromParams);
    }
  }, [lastFour, lastFourFromParams]);

  useEffect(() => {
    if (amountFromParams && amountFromParams !== amount) {
      setAmount(amountFromParams);
    }
  }, [amount, amountFromParams]);

  return (
    <PaylianceDepositSummaryView
      locationString={locationString}
      //isGeographicallyAllowedToDeposit={isGeographicallyAllowedToDeposit}
      isGeographicallyAllowedToDeposit
      onPromotionApply={setPromotion}
      theme={theme}
      type={type}
      navigateTo={navigateTo}
      selectedPaymentMethod={selectedPaymentMethod}
      currentUser={currentUser}
      errorMessages={errorMessages}
      isSubmitting={loading}
      amount={amount}
      submitted={submitted}
      doSubmit={doSubmit}
      fee={fee}
      bonus={bonus}
      promotionId={promotion?.id ?? promotionId}
      onVerificationPress={handleVerificationPress}
      styles={styles}
      cvv={cvv}
      onCvvChange={handleCvvChange}
      error={error}
      isValid={isValid}
    />
  );
};
